<template>
    <div v-loading="$wait.is('loading')" class="card card-f-h w-72 mr-4" element-loading-spinner="el-custom-spinner">
        <Filters @changed="getAll" />

        <header class="flex justify-between items-end border-b border-solid border-gray-300 pb-4 mb-5">
            <h1>{{ $t('help_desk.articles_list') }}</h1>
            <div
                v-if="userCan('create help desk')"
                class="flex h-8 w-8 justify-center items-center bg-red-400 rounded-full text-white hover:bg-red-500 cursor-pointer transition"
                @click="$emit('addNew')"
            >
                <fa-icon :icon="['far', 'plus']" fixed-width class="text-lg" />
            </div>
            <div
                v-else
                class="flex h-8 w-8 justify-center items-center bg-gray-300 rounded-full text-white"
            >
                <fa-icon :icon="['far', 'plus']" fixed-width class="text-lg" />
            </div>
        </header>

        <div
            v-for="article in articlesList"
            :key="article.uuid"
            class="mb-2 py-3 px-4 rounded cursor-pointer transition hover:bg-red-100 hover:shadow-xs"
            :class="{ 'bg-red-100 hover:shadow-xs': selectedArticleUuid === article.uuid }"
            @click="selectArticle(article.uuid)"
        >
            <p class="text-sm">
                {{ article.title }}
            </p>
        </div>
    </div>
</template>
<script>
import queryString from 'query-string';

export default {
    components: {
        Filters: () => import(/* webpackChunkName: "List/Filters" */ './Filters'),
    },
    // @Filip
    props: ['changeIndicator'],

    data() {
        return {
            selectedArticleUuid: null,
            articlesList:        [],
        };
    },

    watch: {
        changeIndicator() {
            this.getAll();
        },
    },

    created() {
        this.getSelectedArticle();
        this.getAll();
    },

    methods: {
        getSelectedArticle() {
            const { uuid } = queryString.parse(window.location.search);
            this.selectedArticleUuid = uuid;
        },

        async getAll() {
            this.$wait.start('loading');
            const { language, receiver } = queryString.parse(window.location.search);
            this.articlesList = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/help_desk`, {
                params: {
                    language: language || 'se',
                    receiver: receiver || 'client',
                },
            }).then(({ data }) => data);
            this.$wait.end('loading');
        },

        selectArticle(uuid) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.uuid = uuid;
            this.$router.replace({ query }).catch(() => {});
            this.selectedArticleUuid = uuid;
            this.$emit('selectionChanged', uuid);
        },
    },
};
</script>
